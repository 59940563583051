/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from "react";
import { useState, useEffect, useReducer, useRef } from "react";
import {
  withRouter,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import axios from "axios";
import DiagnosisEnlarge from "./DiagnosisEnlarge";
import { Row, Col, FormGroup, Grid, FormControl } from "react-bootstrap";
import * as API from "Front/api/emr";
import { appConstants } from "Front/_constants/app.constants.js";
import { NavLink } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import AddDiagnosis from "./AddDiagnosis";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import {
  selectDrugDurationDayOptions,
  selectDrugDurationTimePeriodOptions,
} from "../../../../variables/Variables";
import { Autosave, useAutosave } from "react-autosave";
import Breadcrum from "../../../../components/common/Breadcrum";
import EditIcon from "../../../../assets/img/icons/edit-ic.svg";
import DeleteIcon from "../../../../assets/img/icons/table-delete-ic.svg";
import { useDispatch, useSelector } from "react-redux";

export const ACTIONS = {
  ADD_DIAGNOSIS_LONGTERM: "add_diagnosis_longterm",
  DELETE_DIAGNOSIS_LONGTERM: "delete_diagnosis_longterm",
  ADD_DIAGNOSIS_INTERCURRENT: "add_diagnosis_intercurrent",
  DELETE_DIAGNOSIS_INTERCURRENT: "delete_diagnosis_intercurrent",
  UPDATE_DIAGNOSIS: "update_diagnosis",
  RESET: "reset",
  CLOSE_DIAGNOSIS_ENLARGE: "close_diagnosis_enlarge",
  ADD_DIAGNOSIS_ALL: "add_diagnosis_all",
  DISCONTINUE_DIAGNOSIS_INTERCURRENT: "discontinue_diagnosis_intercurrent",
};

const Diagnosis = () => {
  let history = useHistory();
  const emrData = useSelector((store) => store.emr);
  const [modalStatusDiagnosisEnlarge, setModalStatusDiagnosisEnlarge] =
    useState(false);
  const [diagnosisEnlarge, setDiagnosisEnlarge] = useState({
    type: "longTerm",
    diagonsisCategoryId: "",
    longTermArr: [],
    interCurrentArr: [],
  });
  const [generalInformation, setGeneralInformation] = useState({
    patientName: emrData?.vitalSignsData
      ? emrData.vitalSignsData.patientName
      : "",
    age: emrData?.vitalSignsData ? emrData.vitalSignsData.age : "",
    height: emrData?.vitalSignsData ? emrData.vitalSignsData.height : "",
    weight: emrData?.vitalSignsData ? emrData.vitalSignsData.weight : "",
    bmi: emrData?.vitalSignsData ? emrData.vitalSignsData.bmi : "",
    bp: emrData?.vitalSignsData ? emrData.vitalSignsData.bp : "",
    gender: emrData?.vitalSignsData ? emrData.vitalSignsData.gender : "",
    pulse: emrData?.vitalSignsData ? emrData.vitalSignsData.pulse : "",
    temperature: emrData?.vitalSignsData
      ? emrData.vitalSignsData.temperature
      : "",
  });
  const [duration, setDuration] = useState(
    emrData ? emrData.vitalSignsData.diabetesDuration : "Year Month Day"
  );
  const [diagonsisCategoryList, setDiagonsisCategoryList] = useState([]);
  const [diagnosisSubcategory, setDiagnosisSubcategory] = useState([]);
  const [isSubCategory, setIsSubCategory] = useState(false);
  const [isPageLoad, setIsPageLoad] = useState(true);
  const [alertMsg, setAlertMsg] = useState(null);
  const [diagnosisCount, setDiagnosisCount] = useState(false);
  const [isDataSave, setIsDataSave] = useState(false);
  const [isDataFromEnlarge, setIsDataFromEnlarge] = useState(false);
  const [addDiagnosis, setAddDiagnosis] = useState(true);
  const [modalStatusAddDiagnosis, setModalStatusAddDiagnosis] = useState(false);
  const [clinicId, setClinicId] = useState(localStorage.getItem("clinicId"));
  const [flag, setFlag] = useState("patientDiagonsisList");
  const [isRareDiagnosis, setIsRareDiagnosis] = useState(false);
  const notificationSystem = useRef(null);
  const [diagnosisData, setDiagnosisData] = useState("");
  const [isDiagnosisData, setIsDiagnosisData] = useState(false);
  const [diagnosisDuration, setDiagnosisDuration] = useState({
    no: "",
    period: "",
  });
  const [toggleDuration, setToggleDuration] = useState(
    emrData
      ? emrData.vitalSignsData.toggleDuration
        ? emrData.vitalSignsData.toggleDuration
        : false
      : false
  );
  const [autoSave, setAuoSave] = useState(true);
  const [isAutoSaveUse, setIsAutoSaveUse] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [searchLength, setSearchLength] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [diagnosisAlredyAdded, setDiagnosisAlreadyAdded] = useState(false);

  const location = useLocation();
  let { appointmentId, patientId, printingLanguage } = useParams();
  let count = 0;
  let oldAppointmentId = appointmentId;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const initialState = {
    longTerm: [],
    interCurrent: [],
    longTermData: [],
    intercurrentData: [],
    selectedCategoryId: "",
    selectedDiagnosisCategoryId: "",
    type: "",
  };

  let diagonsisCategoryId = "";
  let arrSubCategoryCheked = [];
  const CancelToken = axios.CancelToken;
  let cancel;

  const getGeneralInfo = async () => {
    if (
      emrData.vitalSignsData &&
      Object.keys(emrData.vitalSignsData).length === 0
    ) {
      let resp = await API.generalInformation({ patientId: patientId });
      if (resp.data && resp.data.status === "Success") {
        setGeneralInformation({
          patientName: resp.data.data.name,
          age: resp.data.data.age,
          height: resp.data.data.height,
          weight: resp.data.data.weight,
          bmi: resp.data.data.bmi,
          bp: resp.data.data.bp,
          gender: resp.data.data.gender,
          pulse: resp.data.data.pulse,
          temperature: resp.data.data.temprature,
        });
        if (resp.data.data.diabitic === "yes") {
          let strDuration = "";
          if (
            resp.data.data.diabtiesDuration?.years === 0 &&
            resp.data.data.diabtiesDuration?.months === 0 &&
            resp.data.data.diabtiesDuration?.days === 0
          ) {
            strDuration = "Day Month Year";
            setToggleDuration(false);
          }
          setToggleDuration(true);
          if (resp.data.data.diabtiesDuration?.years > 0) {
            strDuration = resp.data.data.diabtiesDuration?.years + " years ";
          }
          if (resp.data.data.diabtiesDuration?.months > 0) {
            strDuration += resp.data.data.diabtiesDuration?.months + " months ";
          }
          if (resp.data.data.diabtiesDuration?.days > 0) {
            strDuration += resp.data.data.diabtiesDuration?.days + " days ";
          }
          setDuration(strDuration);
        }
        let radioCategory = document.getElementById("longTerm");
        if (radioCategory !== null) radioCategory.checked = true;
      }
    } else {
      let radioCategory = document.getElementById("longTerm");
      if (radioCategory !== null) radioCategory.checked = true;
    }

    getDiagonsisCategory("longTerm");
  };

  const getPatientDiagonsisData = async () => {
    let resp = await API.patientDiagonsisData({
      patientId: patientId,
      appointmentId: appointmentId,
    });
    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      let tempInterCurrent = {};
      let tempLongTerm = {};
      let tempIntercurrentDiagnosis = [];
      let tempLongTermDiagnosis = [];
      resp.data.intercurrentPatientDiagonsisData[0].data.map((itm) => {
        oldAppointmentId = itm.appointmentId;
        tempInterCurrent = {
          diagonsisSubCategoryId: itm.diagonsisSubCategoryId,
          diagonsisSubCategoryName: itm.diagonsisSubCategoryName,
          patientId: itm.patientId,
          duration: itm.duration,
          type: itm.type,
          diagonsisType: itm.diagonsisType,
          appointmentId: appointmentId,
          isDeleted: itm.deleted,
          discontinue: itm.discontinue,
          closedAt: itm.closedAt,
          oldAppointmentId: oldAppointmentId,
          createdAt: itm?.createdAt
        };
        tempIntercurrentDiagnosis.push(tempInterCurrent);
      });
      resp.data.longTermPatientDiagonsisData.map((itm) => {
        tempLongTerm = {
          diagonsisSubCategoryId: itm.diagonsisSubCategoryId,
          diagonsisSubCategoryName: itm.diagonsisSubCategoryName,
          patientId: itm.patientId,
          duration: itm.duration,
          type: itm.type,
          diagonsisType: itm.diagonsisType,
          appointmentId: itm.appointmentId,
          isDeleted: itm.deleted,
          createdAt: itm?.createdAt
        };
        tempLongTermDiagnosis.push(tempLongTerm);
      });
      let payloadData = {
        longTerm: tempLongTermDiagnosis,
        interCurrent: tempIntercurrentDiagnosis,
      };
      if (
        resp.data.longTermPatientDiagonsisData.length > 0 ||
        resp.data.intercurrentPatientDiagonsisData[0].data.length > 0
      ) {
        dispatch({ type: ACTIONS.ADD_DIAGNOSIS_ALL, payload: payloadData });
      }
    }
  };

  const getDiagonsisCategory = async (type) => {
    let req = {
      type: type,
    };

    let resp = await API.diagonsisCategory(req);
    if (resp.data && resp.data.status === "Success") {
      setDiagonsisCategoryList(resp.data.diagonsisCategoryList.data);
      let catType = "";
      if (isPageLoad) {
        catType = "master";
        diagonsisCategoryId = resp.data.diagonsisCategoryList.data[0]._id;
      } else {
        diagonsisCategoryId = diagnosisEnlarge.diagonsisCategoryId;
        if (diagonsisCategoryId === patientId) catType = "patient";
        else if (diagonsisCategoryId === clinicId) catType = "clinic";
        else catType = "category";
      }
      let radioCategory = document.getElementById(diagonsisCategoryId);
      if (radioCategory !== null) {
        // radioCategory.checked = true;
      } else {
        diagonsisCategoryId = resp.data.diagonsisCategoryList.data[0]._id;
        radioCategory = document.getElementById(
          resp.data.diagonsisCategoryList.data[0]._id
        );
        //radioCategory.checked = true
      }

      getDiagnosisSubCategory("", catType);
    }
  };

  const getDiagnosisSubCategory = async (e, catType) => {
    let radioCategory = document.getElementById("longTerm");
    let type = "longTerm";
    if (radioCategory !== null)
      type = radioCategory.checked === true ? "longTerm" : "intercurrent";

    setDiagnosisSubcategory([]);
    let req = "";
    if (e) {
      diagonsisCategoryId = e.target.id;
    }
    if (catType === "master") {
      req = {
        type: type,
        diagonsisCategoryId: diagonsisCategoryId,
        patientId: patientId,
        clinicId: clinicId,
      };
    } else if (catType === "patient") {
      req = {
        type: type,
        diagonsisCategoryId: "",
        patientId: patientId,
        clinicId: "",
      };
    } else if (catType === "clinic") {
      req = {
        type: type,
        diagonsisCategoryId: "",
        patientId: "",
        clinicId: clinicId,
      };
    } else if (catType === "category") {
      req = {
        type: type,
        diagonsisCategoryId: diagonsisCategoryId,
        patientId: "",
        clinicId: "",
      };
    }

    let resp = await API.diagonsisList(req);
    if (resp.data && resp.data.status === "Success") {
      setFlag(resp.data.flag);
      if (resp.data.flag === "patientDiagonsisList") {
        let radioPatient = document.getElementById(patientId);
        if (radioPatient !== null) radioPatient.checked = true;
        if (resp.data.patientDiagonsisList.length > 0) {
          setDiagnosisSubcategory(resp.data.patientDiagonsisList);
          arrSubCategoryCheked = resp.data.patientDiagonsisList;
        }
      }
      if (resp.data.flag === "clinicDiagonsisList") {
        let radioClinic = document.getElementById(clinicId);
        if (radioClinic !== null) radioClinic.checked = true;
        if (resp.data.clinicDiagonsisList.length > 0) {
          setDiagnosisSubcategory(resp.data.clinicDiagonsisList);
          arrSubCategoryCheked = resp.data.clinicDiagonsisList;
        }
      }
      if (resp.data.flag === "masterDiagonsisCategoryList") {
        if (resp.data.masterDiagonsisCategoryList.data.length > 0) {
          let ele = document.getElementById(
            resp.data.masterDiagonsisCategoryList.data[0].diagonsisCategoryId
          );
          if (ele !== null) ele.checked = true;
          setDiagnosisSubcategory(resp.data.masterDiagonsisCategoryList.data);
          arrSubCategoryCheked = resp.data.masterDiagonsisCategoryList.data;
        }
      }

      selectSubcategory(resp.data.flag);
    }
    if (isPageLoad) {
      getPatientDiagonsisData();
    }
    getSelectedCategory();
    setDiagnosisEnlarge({
      ...diagnosisEnlarge,
      type: type,
      diagonsisCategoryId: diagonsisCategoryId,
    });
  };

  const getDiagnosisSubCategoryEnlarge = async (catType, id) => {
    let radioCategory = document.getElementById("longTerm");
    let type = "longTerm";
    if (radioCategory !== null)
      type = radioCategory.checked === true ? "longTerm" : "intercurrent";

    setDiagnosisSubcategory([]);
    let req = "";

    if (catType === "master") {
      req = {
        type: type,
        diagonsisCategoryId: id,
        patientId: patientId,
        clinicId: clinicId,
      };
    } else if (catType === "patient") {
      req = {
        type: type,
        diagonsisCategoryId: "",
        patientId: patientId,
        clinicId: "",
      };
    } else if (catType === "clinic") {
      req = {
        type: type,
        diagonsisCategoryId: "",
        patientId: "",
        clinicId: clinicId,
      };
    } else if (catType === "category") {
      req = {
        type: type,
        diagonsisCategoryId: id,
        patientId: "",
        clinicId: "",
      };
    }

    let resp = await API.diagonsisList(req);
    if (resp.data && resp.data.status === "Success") {
      setFlag(resp.data.flag);
      if (resp.data.flag === "patientDiagonsisList") {
        let radioPatient = document.getElementById(patientId);
        if (radioPatient !== null) radioPatient.checked = true;
        if (resp.data.patientDiagonsisList.length > 0) {
          setDiagnosisSubcategory(resp.data.patientDiagonsisList);
          arrSubCategoryCheked = resp.data.patientDiagonsisList;
        }
      }
      if (resp.data.flag === "clinicDiagonsisList") {
        let radioClinic = document.getElementById(clinicId);
        if (radioClinic !== null) radioClinic.checked = true;
        if (resp.data.clinicDiagonsisList.length > 0) {
          setDiagnosisSubcategory(resp.data.clinicDiagonsisList);
          arrSubCategoryCheked = resp.data.clinicDiagonsisList;
        }
      }
      if (resp.data.flag === "masterDiagonsisCategoryList") {
        if (resp.data.masterDiagonsisCategoryList.data.length > 0) {
          setDiagnosisSubcategory(resp.data.masterDiagonsisCategoryList.data);
          arrSubCategoryCheked = resp.data.masterDiagonsisCategoryList.data;
        }
      }

      selectSubcategory(resp.data.flag);
    }
    getSelectedCategory();
    setDiagnosisEnlarge({
      ...diagnosisEnlarge,
      type: type,
      diagonsisCategoryId: diagonsisCategoryId,
    });
  };

  const getSelectedCategory = () => {
    let radioCategory = document.getElementsByName("inlineRadioOptions");
    for (var radio of radioCategory) {
      if (radio.checked) diagonsisCategoryId = radio.getAttribute("id");
    }
  };

  const addRareDiagnosis = (
    previousSubCatId,
    diagonsisSubCategoryId,
    diagonsisSubCategoryName,
    type,
    flag,
    diagnosisDuration
  ) => {
    setIsRareDiagnosis(false);
    let msg = "";
    if (type === "longTerm") {
      let payloadData = {
        longTerm: {
          diagonsisSubCategoryId: diagonsisSubCategoryId,
          diagonsisSubCategoryName: diagonsisSubCategoryName,
          patientId: patientId,
          duration:
            flag === "addDiagnosis"
              ? { no: "", period: "" }
              : diagnosisDuration,
          appointmentId: appointmentId,
          type: "longTerm",
          diagonsisType: "clinic",
          isDeleted: false,
        },
        data: {
          diagonsisSubCategoryId: diagonsisSubCategoryId,
          diagonsisSubCategoryName: diagonsisSubCategoryName,
          patientId: patientId,
          duration:
            flag === "addDiagnosis"
              ? { no: "", period: "" }
              : diagnosisDuration,
          appointmentId: appointmentId,
          type: "longTerm",
          diagonsisType: "clinic",
          isDeleted: false,
        },
      };
      if (flag === "addDiagnosis") {
        dispatch({
          type: ACTIONS.ADD_DIAGNOSIS_LONGTERM,
          payload: payloadData,
        });
        msg = "Diagnosis add successfully to mylist";
      } else {
        let idx = state.longTerm.findIndex(
          (x) => x.diagonsisSubCategoryId === previousSubCatId
        );
        state.longTerm[idx] = payloadData.longTerm;
        state.longTermData[idx] = payloadData.data;
        msg = "Diagnosis edit successfully";
      }
      let ele = document.getElementById("longTerm");
      if (ele !== null) ele.checked = true;
    } else {
      let payloadData = {
        interCurrent: {
          diagonsisSubCategoryId: diagonsisSubCategoryId,
          diagonsisSubCategoryName: diagonsisSubCategoryName,
          patientId: patientId,
          duration:
            flag === "addDiagnosis"
              ? { no: "", period: "" }
              : diagnosisDuration,
          appointmentId: appointmentId,
          type: "intercurrent",
          diagonsisType: "clinic",
          isDeleted: false,
        },
        data: {
          diagonsisSubCategoryId: diagonsisSubCategoryId,
          diagonsisSubCategoryName: diagonsisSubCategoryName,
          patientId: patientId,
          duration:
            flag === "addDiagnosis"
              ? { no: "", period: "" }
              : diagnosisDuration,
          appointmentId: appointmentId,
          type: "intercurrent",
          diagonsisType: "clinic",
          isDeleted: false,
        },
      };

      if (flag === "addDiagnosis") {
        dispatch({
          type: ACTIONS.ADD_DIAGNOSIS_INTERCURRENT,
          payload: payloadData,
        });
        msg = "Diagnosis add successfully to mylist";
      } else {
        let idx = state.interCurrent.findIndex(
          (x) => x.diagonsisSubCategoryId === previousSubCatId
        );
        state.interCurrent[idx] = payloadData.interCurrent;
        state.intercurrentData[idx] = payloadData.data;
        msg = "Diagnosis edit successfully";
      }
      let ele = document.getElementById("interCurrent");
      if (ele !== null) ele.checked = true;
    }

    const notification = notificationSystem.current;
    notification.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: <div>{msg}</div>,
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
    setIsRareDiagnosis(true);
  };

  const selectSubcategory = (flagList) => {
    let tempArr = [];
    let radioCategory = document.getElementById("longTerm");
    if (radioCategory !== null)
      tempArr =
        radioCategory.checked === true ? state.longTerm : state.interCurrent;
    tempArr.map((item) => {
      if (arrSubCategoryCheked.length > 0) {
        arrSubCategoryCheked.map((subcat) => {
          if (flagList === "patientDiagonsisList") {
            if (subcat.diagonsisSubCategoryId === item.diagonsisSubCategoryId) {
              checkedComplaint(subcat.diagonsisSubCategoryId);
            }
          } else {
            if (subcat._id === item.diagonsisSubCategoryId) {
              checkedComplaint(subcat._id);
            }
          }
        });
      }
    });
  };

  // const handleSaveSymptoms = (e) => {
  //   if (state.longTerm.length > 0 || state.interCurrent.length > 0) {
  //     submitHandler()
  //   } else {
  //     setDiagnosisCount(true)
  //   }
  // }

  const checkedComplaint = (subcatId) => {
    let chkSubCategory = document.getElementsByName("subcat");
    for (var subCategory of chkSubCategory) {
      if (subcatId === subCategory.getAttribute("id")) {
        subCategory.checked = true;
      }
    }
  };

  const addOrDeleteSubcategory = (e) => {
    let type = "longTerm";
    setDiagnosisCount(false);
    let diagonsisType = "master";
    if (flag === "clinicDiagonsisList") {
      diagonsisType = "clinic";
    } else if (flag === "patientDiagonsisList") {
      diagonsisType = "patient";
    }
    let radioCategory = document.getElementById("longTerm");
    if (radioCategory !== null)
      type = radioCategory.checked === true ? "longTerm" : "intercurrent";
    if (e.target.checked) {
      if (type === "longTerm") {
        let payloadData = {
          longTerm: {
            diagonsisSubCategoryId: e.target.id,
            diagonsisSubCategoryName: e.target.value,
            patientId: patientId,
            duration: { no: "", period: "" },
            type: "longTerm",
            diagonsisType: diagonsisType,
            appointmentId: appointmentId,
            isDeleted: false,
          },
        };
        dispatch({
          type: ACTIONS.ADD_DIAGNOSIS_LONGTERM,
          payload: payloadData,
        });
      } else {
        let payloadData = {
          interCurrent: {
            diagonsisSubCategoryId: e.target.id,
            diagonsisSubCategoryName: e.target.value,
            patientId: patientId,
            duration: { no: "", period: "" },
            type: "intercurrent",
            diagonsisType: diagonsisType,
            appointmentId: appointmentId,
            isDeleted: false,
            discontinue: "-1",
            closedAt: null,
            oldAppointmentId: oldAppointmentId,
          },
        };
        dispatch({
          type: ACTIONS.ADD_DIAGNOSIS_INTERCURRENT,
          payload: payloadData,
        });
      }
    } else {
      if (type === "longTerm") {
        dispatch({
          type: ACTIONS.DELETE_DIAGNOSIS_LONGTERM,
          payload: { diagonsisSubCategoryId: e.target.id },
        });
      } else {
        dispatch({
          type: ACTIONS.DELETE_DIAGNOSIS_INTERCURRENT,
          payload: { diagonsisSubCategoryId: e.target.id },
        });
      }
    }
  };

  const successAlert = (msg) => {
    setAlertMsg(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => {
          setAlertMsg(null);
          let ele = document.getElementById("btnSaveDiagnosis");
          if (ele !== null) ele.disabled = false;
        }}
        confirmBtnBsStyle="info"
      >
        {msg}{" "}
      </SweetAlert>
    );
    setTimeout(function () {
      setAlertMsg(null);
      let ele = document.getElementById("btnSaveDiagnosis");
      if (ele !== null) ele.disabled = false;
    }, 3000);
  };

  function unCheckSubcategory(diagonsisSubCategoryId) {
    let chkbox_subact = document.getElementsByName("subcat");
    for (var chk of chkbox_subact) {
      if (chk.id === diagonsisSubCategoryId) {
        chk.checked = false;
      }
    }
  }

  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.ADD_DIAGNOSIS_LONGTERM: {
        return {
          ...state,
          longTerm: [...state.longTerm, action.payload.longTerm],
          longTermData: [...state.longTermData, action.payload.longTerm],
        };
      }

      case ACTIONS.DELETE_DIAGNOSIS_LONGTERM: {
        unCheckSubcategory(action.payload.diagonsisSubCategoryId);
        state.longTermData.map((itm) => {
          if (
            itm.diagonsisSubCategoryId === action.payload.diagonsisSubCategoryId
          ) {
            itm.isDeleted = true;
          }
        });
        return {
          ...state,
          longTerm: state.longTerm.filter(
            (itm) =>
              itm.diagonsisSubCategoryId !==
              action.payload.diagonsisSubCategoryId
          ),
        };
      }

      case ACTIONS.UPDATE_DURATION: {
        return {
          ...state,
          longTerm: state.longTerm,
          longTermData: state.longTermData,
        };
      }

      case ACTIONS.RESET: {
        return {
          longTerm: [],
          longTermData: [],
          interCurrent: [],
          intercurrentData: [],
          selectedCategoryId: "",
          type: "",
        };
      }

      case ACTIONS.ADD_DIAGNOSIS_INTERCURRENT: {
        return {
          ...state,
          interCurrent: [...state.interCurrent, action.payload.interCurrent],
          intercurrentData: [
            ...state.intercurrentData,
            action.payload.interCurrent,
          ],
        };
      }

      case ACTIONS.DELETE_DIAGNOSIS_INTERCURRENT: {
        unCheckSubcategory(action.payload.diagonsisSubCategoryId);
        state.intercurrentData.map((itm) => {
          if (
            itm.diagonsisSubCategoryId === action.payload.diagonsisSubCategoryId
          ) {
            itm.isDeleted = true;
          }
        });
        return {
          ...state,
          interCurrent: state.interCurrent.filter(
            (itm) =>
              itm.diagonsisSubCategoryId !==
              action.payload.diagonsisSubCategoryId
          ),
        };
      }

      case ACTIONS.DISCONTINUE_DIAGNOSIS_INTERCURRENT: {
        state.intercurrentData.map((itm) => {
          if (
            itm.diagonsisSubCategoryId === action.payload.diagonsisSubCategoryId
          ) {
            itm.closedAt = moment(new Date()).format("DD-MMM-YYYY");
            itm.discontinue = itm.discontinue === "0" ? "1" : "0";
          }
        });
        return {
          ...state,
        };
      }

      case ACTIONS.UPDATE_DIAGNOSIS: {
        setIsSubCategory(true);
        let eleType = document.getElementById(action.payload.type);
        if (eleType !== null) eleType.checked = true;
        let ele = document.getElementById(action.payload.selectedCategoryId);
        if (ele !== null) ele.checked = true;
        setIsDataFromEnlarge(true);
        return {
          ...state,
          longTerm: action.payload.longTerm,
          longTermData: action.payload.longTermData,
          intercurrentData: action.payload.intercurrentData,
          interCurrent: action.payload.interCurrent,
          selectedCategoryId: action.payload.selectedCategoryId,
        };
      }

      case ACTIONS.CLOSE_DIAGNOSIS_ENLARGE: {
        setIsSubCategory(true);

        let ele = document.getElementById(action.payload.selectedCategoryId);
        if (ele !== null) ele.checked = true;
        let catType = "";
        if (action.payload.selectedCategoryId === patientId)
          catType = "patient";
        else if (action.payload.selectedCategoryId === clinicId)
          catType = "clinic";
        else catType = "category";

        getDiagnosisSubCategoryEnlarge(
          catType,
          action.payload.selectedCategoryId
        );

        return {
          ...state,
          longTerm: state.longTerm,
          interCurrent: state.interCurrent,
        };
      }

      case ACTIONS.ADD_DIAGNOSIS_ALL: {
        let eleCat =
          action.payload.longTerm.length > 0
            ? document.getElementById("longTerm")
            : action.payload.interCurrent.length > 0
              ? document.getElementById("interCurrent")
              : document.getElementById("longTerm");
        eleCat.checked = true;
        let elePatient = document.getElementById(patientId);
        if (elePatient !== null) elePatient.checked = true;
        getDiagnosisSubCategory("", "patient");
        action.payload.longTerm.map((itm) => {
          let ele = document.getElementById(itm.diagonsisSubCategoryId);
          if (ele !== null) ele.checked = true;
        });
        return {
          ...state,
          longTerm: action.payload.longTerm,
          longTermData: action.payload.longTerm,
          interCurrent: [...action.payload.interCurrent],
          intercurrentData: [...action.payload.interCurrent],
        };
      }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  function autocomplete(inp, arr) {
    function closeAllLists(elmnt) {
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }

    var currentFocus;

    var a,
      b,
      i,
      val = inp.value;
    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    a = document.createElement("DIV");
    a.setAttribute("id", inp.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items autocompletedropdownmenu");
    inp.parentNode.appendChild(a);

    for (i = 0; i < arr.length; i++) {
      b = document.createElement("DIV");
      b.setAttribute("class", "");
      b.innerHTML += `${arr[i].diagonsisSubCategoryName} (${arr[i].diagonsisType === "master"
        ? arr[i].diagonsisCategoryName
        : "My Clinic"
        })`;
      b.innerHTML +=
        "<input type='hidden' diagonsisType='" +
        arr[i].diagonsisType +
        "'diagonsisSubCategoryId='" +
        arr[i].diagonsisSubCategoryId +
        "'diagonsisCategoryId='" +
        arr[i].diagonsisCategoryId +
        "'dtype='" +
        arr[i].type +
        "'value='" +
        arr[i].diagonsisSubCategoryName +
        "'>";
      b.addEventListener("click", function (e) {
        let subCategoryId = this.getElementsByTagName("input")[0].getAttribute(
          "diagonsisSubCategoryId"
        );
        let diagonsisType =
          this.getElementsByTagName("input")[0].getAttribute("diagonsisType");
        let diagnosis =
          this.getElementsByTagName("input")[0].getAttribute("value");
        let categoryId = this.getElementsByTagName("input")[0].getAttribute(
          "diagonsisCategoryId"
        );
        let type = this.getElementsByTagName("input")[0].getAttribute("dtype");
        addDiagnosisAutoComplete(
          subCategoryId,
          diagonsisType,
          diagnosis,
          categoryId,
          type
        );
        closeAllLists();
      });
      a.appendChild(b);
    }
  }

  const addDiagnosisAutoComplete = (
    subCategoryId,
    diagonsisType,
    diagnosis,
    categoryId,
    type
  ) => {
    if (!diagnosisValidate(type, subCategoryId)) {
      if (type === "longTerm") {
        let payloadData = {
          longTerm: {
            diagonsisSubCategoryId: subCategoryId,
            diagonsisSubCategoryName: diagnosis,
            patientId: patientId,
            duration: { no: "", period: "" },
            type: "longTerm",
            diagonsisType: diagonsisType,
            appointmentId: appointmentId,
            isDeleted: false,
          },
        };
        dispatch({
          type: ACTIONS.ADD_DIAGNOSIS_LONGTERM,
          payload: payloadData,
        });
      } else {
        let payloadData = {
          interCurrent: {
            diagonsisSubCategoryId: subCategoryId,
            diagonsisSubCategoryName: diagnosis,
            patientId: patientId,
            duration: { no: "", period: "" },
            type: "intercurrent",
            diagonsisType: diagonsisType,
            appointmentId: appointmentId,
            isDeleted: false,
            discontinue: "-1",
            closedAt: null,
            oldAppointmentId: oldAppointmentId,
          },
        };
        dispatch({
          type: ACTIONS.ADD_DIAGNOSIS_INTERCURRENT,
          payload: payloadData,
        });
      }
      diagonsisCategoryId = categoryId;
      let ele = document.getElementById(categoryId);
      if (ele != null) ele.checked = true;
      getDiagnosisSubCategory(
        "",
        diagonsisType === "master" ? "category" : diagonsisType
      );
      setTimeout(() => {
        ele = document.getElementById(subCategoryId);
        if (ele != null) ele.checked = true;
        ele = document.getElementById("txtSearchDiagnosis");
        if (ele != null) ele.value = "";
        ele = document.getElementById("autoCompleteBox");
        //ele.classList.remove("autoCompleteBackDrop");
      }, 300);
    }
  };

  const deleteLongTermConfirm = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch({
              type: ACTIONS.DELETE_DIAGNOSIS_LONGTERM,
              payload: { diagonsisSubCategoryId: id },
            }),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteIntercurrentConfirm = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch({
              type: ACTIONS.DELETE_DIAGNOSIS_INTERCURRENT,
              payload: { diagonsisSubCategoryId: id },
            }),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDiscontinueIntercurrent = (id) => {
    confirmAlert({
      title: "Confirm to Discontinue",
      message: "Are you sure you want to discontinue this diagnosis?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch({
              type: ACTIONS.DISCONTINUE_DIAGNOSIS_INTERCURRENT,
              payload: { diagonsisSubCategoryId: id },
            }),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const editDiagnosisPopUp = (itm) => {
    setModalStatusAddDiagnosis(true);
    setAddDiagnosis(false);
    let formData = {
      diagonsisSubCategoryName: itm.diagonsisSubCategoryName,
      type: itm.type,
      duration: itm.duration,
      diagonsisSubCategoryId: itm.diagonsisSubCategoryId,
      patientId: patientId,
      appointmentId: appointmentId,
    };
    setDiagnosisData(formData);
    setDiagnosisDuration(itm.duration);
  };

  const handleDuration = (e, type, key) => {
    if (type === "longTerm") {
      if (key === "no") {
        let ele = document.getElementById(
          e.target.getAttribute("subcatid") + "-no"
        );
        if (ele != null) ele.classList.remove("duration-required");
      }

      if (key === "period") {
        let ele = document.getElementById(
          e.target.getAttribute("subcatid") + "-period"
        );
        if (ele != null) ele.classList.remove("duration-required");
      }
      state.longTermData.map((itm) => {
        if (itm.diagonsisSubCategoryId === e.target.getAttribute("subcatid")) {
          if (key === "no") {
            itm.duration.no = e.target.value === "No" ? "" : e.target.value;
            if (e.target.value != "No" && itm.duration.period === "") {
              itm.duration.period = "Day";
              let ele = document.getElementById(
                e.target.getAttribute("subcatid") + "-period"
              );
              if (ele != null) ele.classList.remove("duration-required");
            } else if (e.target.value != "No" && itm.duration.period != "") {
              itm.duration.period = itm.duration.period;
            } else if (e.target.value === "No") {
              itm.duration.period = "";
            }
          } else if (key === "period") {
            itm.duration.period =
              e.target.value === "D/W/M/Y" ? "" : e.target.value;
            if (e.target.value != "D/W/M/Y" && itm.duration.no === "") {
              itm.duration.no = "1";
              let ele = document.getElementById(
                e.target.getAttribute("subcatid") + "-no"
              );
              if (ele != null) ele.classList.remove("duration-required");
            } else if (e.target.value != "D/W/M/Y" && itm.duration.no != "") {
              itm.duration.no = itm.duration.no;
            } else if (e.target.value === "D/W/M/Y") {
              itm.duration.no = "";
            }
          }
        }
      });

      state.longTerm.map((itm) => {
        if (itm.diagonsisSubCategoryId === e.target.getAttribute("subcatid")) {
          if (key === "no") {
            itm.duration.no = e.target.value === "No" ? "" : e.target.value;
            if (e.target.value != "No" && itm.duration.period === "") {
              itm.duration.period = "Day";
              let ele = document.getElementById(
                e.target.getAttribute("subcatid") + "-period"
              );
              if (ele != null) ele.classList.remove("duration-required");
            } else if (e.target.value != "No" && itm.duration.period != "") {
              itm.duration.period = itm.duration.period;
            } else if (e.target.value === "No") {
              itm.duration.period = "";
            }
          } else if (key === "period") {
            itm.duration.period =
              e.target.value === "D/W/M/Y" ? "" : e.target.value;
            if (e.target.value != "D/W/M/Y" && itm.duration.no === "") {
              itm.duration.no = "1";
              let ele = document.getElementById(
                e.target.getAttribute("subcatid") + "-no"
              );
              if (ele != null) ele.classList.remove("duration-required");
            } else if (e.target.value != "D/W/M/Y" && itm.duration.no != "") {
              itm.duration.no = itm.duration.no;
            } else if (e.target.value === "D/W/M/Y") {
              itm.duration.no = "";
            }
          }
        }
      });
    } else {
      if (key === "no") {
        let ele = document.getElementById(
          e.target.getAttribute("subcatid") + "-no"
        );
        if (ele != null) ele.classList.remove("duration-required");
      }

      if (key === "period") {
        let ele = document.getElementById(
          e.target.getAttribute("subcatid") + "-period"
        );
        if (ele != null) ele.classList.remove("duration-required");
      }

      state.intercurrentData.map((itm) => {
        if (itm.diagonsisSubCategoryId === e.target.getAttribute("subcatid")) {
          if (key === "no") {
            itm.duration.no = e.target.value === "No" ? "" : e.target.value;
            if (e.target.value != "No" && itm.duration.period === "") {
              itm.duration.period = "Day";
              let ele = document.getElementById(
                e.target.getAttribute("subcatid") + "-period"
              );
              if (ele != null) ele.classList.remove("duration-required");
            } else if (e.target.value != "No" && itm.duration.period != "") {
              itm.duration.period = itm.duration.period;
            } else if (e.target.value === "No") {
              itm.duration.period = "";
            }
          } else if (key === "period") {
            itm.duration.period =
              e.target.value === "D/W/M/Y" ? "" : e.target.value;
            if (e.target.value != "D/W/M/Y" && itm.duration.no === "") {
              itm.duration.no = "1";
              let ele = document.getElementById(
                e.target.getAttribute("subcatid") + "-no"
              );
              if (ele != null) ele.classList.remove("duration-required");
            } else if (e.target.value != "D/W/M/Y" && itm.duration.no != "") {
              itm.duration.no = itm.duration.no;
            } else if (e.target.value === "D/W/M/Y") {
              itm.duration.no = "";
            }
          }
        }
      });

      state.interCurrent.map((itm) => {
        if (itm.diagonsisSubCategoryId === e.target.getAttribute("subcatid")) {
          if (key === "no") {
            itm.duration.no = e.target.value === "No" ? "" : e.target.value;
            if (e.target.value != "No" && itm.duration.period === "") {
              itm.duration.period = "Day";
              let ele = document.getElementById(
                e.target.getAttribute("subcatid") + "-period"
              );
              if (ele != null) ele.classList.remove("duration-required");
            } else if (e.target.value != "No" && itm.duration.period != "") {
              itm.duration.period = itm.duration.period;
            } else if (e.target.value === "No") {
              itm.duration.period = "";
            }
          } else if (key === "period") {
            itm.duration.period =
              e.target.value === "D/W/M/Y" ? "" : e.target.value;
            if (e.target.value != "D/W/M/Y" && itm.duration.no === "") {
              itm.duration.no = "1";
              let ele = document.getElementById(
                e.target.getAttribute("subcatid") + "-no"
              );
              if (ele != null) ele.classList.remove("duration-required");
            } else if (e.target.value != "D/W/M/Y" && itm.duration.no != "") {
              itm.duration.no = itm.duration.no;
            } else if (e.target.value === "D/W/M/Y") {
              itm.duration.no = "";
            }
          }
        }
      });
    }

    dispatch({ type: ACTIONS.UPDATE_DURATION });
  };

  // const submitHandler = async (e) => {
  //   let flagLongTerm=true
  //   state.longTermData.map((itm) => {
  //     if(itm.duration.no!=""&& itm.duration.period==="") {
  //       flagLongTerm=false
  //       let ele= document.getElementById(itm.diagonsisSubCategoryId+"-period")
  //       if(ele!==null)
  //       ele.classList.add("duration-required")
  //     }else if(itm.duration.no==="" && itm.duration.period!="" ){
  //       flagLongTerm=false
  //        let ele= document.getElementById(itm.diagonsisSubCategoryId+"-no")
  //        if(ele!==null)
  //        ele.classList.add("duration-required")
  //     } else if(itm.duration.no ==="" && itm.duration.period==="")
  //     {
  //       //flagLongTerm=true
  //       let ele= document.getElementById(itm.diagonsisSubCategoryId+"-no")
  //        if(ele!==null)
  //        ele.classList.remove("duration-required")
  //        ele= document.getElementById(itm.diagonsisSubCategoryId+"-period")
  //       if(ele!==null)
  //       ele.classList.remove("duration-required")

  //     }
  //   })

  //   let flagIntercurrent=true
  //   state.intercurrentData.map((itm) => {
  //     if(itm.duration.no!=""&& itm.duration.period==="") {
  //       flagIntercurrent=false
  //       let ele= document.getElementById(itm.diagonsisSubCategoryId+"-period")
  //       if(ele!==null)
  //       ele.classList.add("duration-required")
  //     }else if(itm.duration.no==="" && itm.duration.period!="" ){
  //       flagIntercurrent=false
  //        let ele= document.getElementById(itm.diagonsisSubCategoryId+"-no")
  //        if(ele!==null)
  //        ele.classList.add("duration-required")
  //     } else if(itm.duration.no ==="" && itm.duration.period==="")
  //     {
  //       //flagIntercurrent=true
  //       let ele= document.getElementById(itm.diagonsisSubCategoryId+"-no")
  //        if(ele!==null)
  //        ele.classList.remove("duration-required")
  //        ele= document.getElementById(itm.diagonsisSubCategoryId+"-period")
  //       if(ele!==null)
  //       ele.classList.remove("duration-required")

  //     }
  //   })

  //  if(flagLongTerm && flagIntercurrent)
  //  {
  //   let ele = document.getElementById("btnSaveDiagnosis")
  //   if (ele !== null)
  //     ele.disabled = true
  //   let res = await API.addDiagonsis({ longTermData: state.longTermData, intercurrentData: state.intercurrentData });
  //   if (res.data.status === "Success" && res.data.statusCode === 200) {
  //     setIsDataSave(true)
  //     successAlert("Diagnosis Data Save Successfully")
  //  }

  //   }
  // }

  const submitHandlerAutoSave = async () => {
    let flagLongTerm = true;
    let flagIntercurrent = true;

    state.longTermData.map((itm) => {
      if (itm.duration.no != "" && itm.duration.period === "") {
        flagLongTerm = false;
        let ele = document.getElementById(
          itm.diagonsisSubCategoryId + "-period"
        );
        if (ele !== null) ele.classList.add("duration-required");
      } else if (itm.duration.no === "" && itm.duration.period != "") {
        flagLongTerm = false;
        let ele = document.getElementById(itm.diagonsisSubCategoryId + "-no");
        if (ele !== null) ele.classList.add("duration-required");
      } else if (itm.duration.no === "" && itm.duration.period === "") {
        let ele = document.getElementById(itm.diagonsisSubCategoryId + "-no");
        if (ele !== null) ele.classList.remove("duration-required");
        ele = document.getElementById(itm.diagonsisSubCategoryId + "-period");
        if (ele !== null) ele.classList.remove("duration-required");
      }
    });

    state.intercurrentData.map((itm) => {
      if (itm.duration.no != "" && itm.duration.period === "") {
        flagIntercurrent = false;
        let ele = document.getElementById(
          itm.diagonsisSubCategoryId + "-period"
        );
        if (ele !== null) ele.classList.add("duration-required");
      } else if (itm.duration.no === "" && itm.duration.period != "") {
        flagIntercurrent = false;
        let ele = document.getElementById(itm.diagonsisSubCategoryId + "-no");
        if (ele !== null) ele.classList.add("duration-required");
      } else if (itm.duration.no === "" && itm.duration.period === "") {
        let ele = document.getElementById(itm.diagonsisSubCategoryId + "-no");
        if (ele !== null) ele.classList.remove("duration-required");
        ele = document.getElementById(itm.diagonsisSubCategoryId + "-period");
        if (ele !== null) ele.classList.remove("duration-required");
      }
    });
    if (flagLongTerm && flagIntercurrent) {
      //console.log(state,"stateeeeeeee")
      let res = await API.addDiagonsis({
        appointmentId: appointmentId,
        patientId: patientId,
        longTermData: state.longTermData,
        intercurrentData: state.intercurrentData,
      });
      if (res.data.status === "Success" && res.data.statusCode === 200) {
        //setIsDataSave(true)
      }
    }
  };

  useEffect(() => {
    if (!isPageLoad) {
      if (isRareDiagnosis) {
        getDiagnosisSubCategory("", "clinic");
      }
    }
  }, [isRareDiagnosis]);

  useEffect(() => {
    setDiagnosisEnlarge({
      ...diagnosisEnlarge,
      longTermArr: state.longTerm,
      interCurrentArr: state.interCurrent,
    });
  }, [state.longTerm, state.interCurrent]);

  useEffect(() => {
    if (isPageLoad) {
      getGeneralInfo();
      setIsPageLoad(false);
    } else {
      if (isSubCategory) {
        setIsSubCategory(false);
      }
    }
  }, [isSubCategory]);

  useEffect(() => {
    if (!isPageLoad) {
      let catType = "";
      if (state.selectedCategoryId === patientId) catType = "patient";
      else if (state.selectedCategoryId === clinicId) catType = "clinic";
      else catType = "category";
      getDiagnosisSubCategoryEnlarge(catType, state.selectedCategoryId);
      setIsDataFromEnlarge(false);
    }
  }, [isDataFromEnlarge]);

  useEffect(() => {
    if (isDataSave) getDiagonsisCategory("longTerm");
    setIsDataSave(false);
  }, [isDataSave]);

  useEffect(() => {
    if (isDiagnosisData)
      // getPatientDiagonsisData()
      setIsDiagnosisData(false);
  }, [isDiagnosisData]);

  useEffect(() => {
    if (!isPageLoad) {
      if (autoSave) setAuoSave(false);
      else setAuoSave(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (state.longTermData.length > 0 || state.intercurrentData.length > 0) {
      setIsAutoSaveUse(true);
    }
  }, [state]);

  useAutosave({
    data: autoSave,
    onSave: submitHandlerAutoSave,
    saveOnUnmount: isAutoSaveUse,
  });

  const searchDiagnosis = async (e) => {
    setIsSpecialCharacter(false);
    setDiagnosisAlreadyAdded(false);
    let search = e.target.value.trim();
    if (search.length >= 100) {
      setSearchLength(true);
      return;
    } else setSearchLength(false);
    let sessionToken = localStorage.getItem("token");
    let radioCategory = document.getElementById("longTerm");
    let type = "longTerm";
    if (radioCategory !== null)
      type = radioCategory.checked === true ? "longTerm" : "intercurrent";
    if (search !== "") {
      // if (!/[^a-zA-Z0-9  .]/.test(search)) {
      if (cancel !== undefined) {
        cancel();
      }
      let strUrl = appConstants.paAppURL;
      strUrl += "diagonsisSearch";
      axios
        .post(
          strUrl,
          { search: search, type: type },
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
            headers: {
              Authorization: sessionToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let ele = document.getElementById("autoCompleteBox");
          if (response.data.searchData.length > 0) {
            setNoDataFound(false);
            //ele.classList.add("autoCompleteBackDrop");
          } else {
            setNoDataFound(true);
            //ele.classList.remove("autoCompleteBackDrop");
          }

          autocomplete(
            document.getElementById("txtSearchDiagnosis"),
            response.data.searchData
          );
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
          }
        });
      // } else {
      //   setIsSpecialCharacter(true);
      // }
    } else {
      closeLists();
      setNoDataFound(false);
      setTimeout(() => {
        let ele = document.getElementById("autoCompleteBox");
        //ele.classList.remove("autoCompleteBackDrop");
      }, 200);
    }
  };

  function closeLists(elmnt) {
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
  }

  const diagnosisValidate = (type, diagnosisId) => {
    setDiagnosisAlreadyAdded(false);
    let flag = false;
    let diagnosisArr =
      type === "longTerm" ? state.longTerm : state.interCurrent;
    diagnosisArr.map((diagnosis) => {
      if (diagnosis.diagonsisSubCategoryId === diagnosisId) {
        flag = true;
        setDiagnosisAlreadyAdded(true);
      }
    });
    return flag;
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          let ele = document.getElementById("txtSearchDiagnosis");
          if (ele != null) ele.value = "";
          closeLists();
          setNoDataFound(false);
          setDiagnosisAlreadyAdded(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div
      className="main-content emr-wrapper dashboard-2-0"
      style={{ padding: "15px 0px" }}
    >
      <div>
        <NotificationSystem ref={notificationSystem} style={style} />
      </div>
      <span> {alertMsg}</span>
      <Grid fluid>
        <Breadcrum />
        <div>
          <div className="general-information">
            <div className="emr_patient">
              <div className="card_header">
                <div className="card_header_inner">
                  <ul className="patient_details">
                    <li>
                      <a className="user-info-img">
                        {/* <span><img src={GeneralUsrImg} alt="" /> </span> */}
                        {generalInformation.patientName}
                      </a>
                    </li>
                    <span className="flex">
                      <li>
                        <a>
                          <span>Age: </span> {generalInformation.age} Y /{" "}
                          {generalInformation.gender === "Male" ? " M" : " F"}
                        </a>
                      </li>
                      <li>
                        <a>
                          <span>
                            Height{generalInformation.height > 0 ? ":" : ""}{" "}
                          </span>{" "}
                          {generalInformation.height > 0
                            ? generalInformation.height
                            : ""}{" "}
                          {generalInformation.height > 0 ? "CM" : ""}
                        </a>
                      </li>
                      <li>
                        <a>
                          <span>
                            Weight{generalInformation.weight > 0 ? ":" : ""}{" "}
                          </span>{" "}
                          {generalInformation.weight > 0
                            ? generalInformation.weight
                            : ""}{" "}
                          {generalInformation.weight > 0 ? "KG" : ""}
                        </a>
                      </li>
                      <li>
                        <a>
                          <span>
                            BMI{generalInformation.bmi > 0 ? ":" : ""}{" "}
                          </span>{" "}
                          {generalInformation.bmi > 0
                            ? generalInformation.bmi
                            : ""}
                        </a>
                      </li>
                      <li>
                        <a>
                          <span>
                            BP{generalInformation.bp !== "/" ? ":" : ""}{" "}
                          </span>{" "}
                          {generalInformation.bp !== "/"
                            ? generalInformation.bp
                            : ""}{" "}
                          {generalInformation.bp !== "/" ? " mmHg" : ""}
                        </a>
                      </li>
                      <li>
                        <a>
                          <span>
                            Pulse{generalInformation.pulse > 0 ? ":" : ""}{" "}
                          </span>{" "}
                          {generalInformation.pulse > 0
                            ? generalInformation.pulse
                            : ""}{" "}
                          {generalInformation.pulse > 0 ? "/min" : ""}
                        </a>
                      </li>
                      <li>
                        <a>
                          <span>
                            Temperature
                            {generalInformation.temperature > 0 ? ":" : ""}{" "}
                          </span>{" "}
                          {generalInformation.temperature > 0
                            ? generalInformation.temperature
                            : ""}{" "}
                          {generalInformation.temperature > 0 ? "F" : ""}{" "}
                        </a>
                      </li>
                      {toggleDuration && (
                        <li>
                          <a>
                            <span>Diabetes Duration: </span>{" "}
                            {emrData
                              ? emrData?.diabetesDuration
                                ? emrData?.diabetesDuration
                                : duration
                              : duration}{" "}
                          </a>
                        </li>
                      )}
                    </span>
                  </ul>
                  <div className="patient_history_btn">
                    {/* {localStorage.getItem("is_Navigate_From_Doctor_Screen") && (JSON.parse(localStorage.getItem("is_Navigate_From_Doctor_Screen"))===true)&&} */}
                    <NavLink
                      to={`/patient-details`}
                      style={{ marginRight: "10px" }}
                      className="btn btn-fill btn-light btn-sm"
                    >
                      Go Back
                    </NavLink>

                    <NavLink
                      to={`/history/${appointmentId}/${patientId}/${printingLanguage}`}
                      className="btn btn-fill btn-light pull-right btn-sm"
                    >
                      All Visit
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pending-action" onClick={() => this.handleSideModal("PendingAction")}>
              <div className='pending-ic'>
                <img src={PendingIc} alt='' />
              </div>
              <div className="pending-title">
                <h4>Pending Action <strong>3</strong></h4>
              </div>
            </div> */}
          </div>
          <div className="card-content-inner">
            <div className="emr-menu-list">
              <ul>
                <li>
                  <NavLink
                    to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                  >
                    <span className="sidebar-mini">Vital Signs</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                  >
                    <span className="sidebar-mini">Lab Tests</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                  >
                    <span className="sidebar-mini">Investigation</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/complaints/${appointmentId}/${patientId}/${printingLanguage}`}
                    className="nav-link"
                  >
                    <span className="sidebar-mini">Complaint</span>
                  </NavLink>
                </li>
                <li className="current">
                  <NavLink
                    to={`/diagnosis/${appointmentId}/${patientId}/${printingLanguage}`}
                    className="nav-link"
                  >
                    <span className="sidebar-mini">Diagnosis</span>
                  </NavLink>
                </li>
                {/* <li><a>Investigation Advised</a></li>
                <li><a>All Investigations</a></li> */}
                <li>
                  <a
                    onClick={(e) =>
                      history.push({
                        pathname: `/prescription/${appointmentId}/${patientId}/${printingLanguage}`,
                        diagnosisData: state.longTerm.concat(
                          state.interCurrent
                        ),
                      })
                    }
                    className="sidebar-mini"
                  >
                    Prescription
                  </a>
                </li>
              </ul>
            </div>
            {diagnosisCount && (
              <div>
                <p style={{ color: "red" }}>There is no data to save</p>
              </div>
            )}
            {diagnosisAlredyAdded && (
              <div style={{ color: "red" }}>
                <p>This diagnosis already added.</p>
              </div>
            )}
            <div className="emr-tab-content">
              <Row>
                <Col lg={6} md={6}>
                  <div className="center-content">
                    <h5>Long Term Diagnosis</h5>
                    <div className="center-content-table table-responsive-new">
                      <table className="table table-bordered diagnosis-table-style">
                        <thead>
                          <tr>
                            <th scope="col" style={{ minWidth: "40px" }}>
                              #
                            </th>
                            <th scope="col" style={{ minWidth: "150px" }}>
                              Long term diagnosis
                            </th>
                            <th scope="col" style={{ minWidth: "90px" }}>
                              Added On
                            </th>
                            <th scope="col" style={{ minWidth: "80px" }}>
                              Duration
                            </th>
                            <th scope="col" style={{ minWidth: "80px" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.longTerm.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                              <th scope="row">{idx + 1}</th>
                              <td>{item.diagonsisSubCategoryName}</td>
                              <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                              <td>
                                <div className="durationSelects">
                                  <select
                                    className="daysSelect"
                                    onChange={(e) => {
                                      handleDuration(e, "longTerm", "no");
                                    }}
                                    subcatid={item.diagonsisSubCategoryId}
                                    componentclassName="select"
                                    id={item.diagonsisSubCategoryId + "-no"}
                                    name="duration"
                                    value={item.duration.no}
                                  >
                                    <option value="No">No</option>
                                    {selectDrugDurationDayOptions.map(function (
                                      option
                                    ) {
                                      return (
                                        <option value={option.value}>
                                          {option.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <select
                                    className="weekSelect"
                                    onChange={(e) => {
                                      handleDuration(e, "longTerm", "period");
                                    }}
                                    subcatid={item.diagonsisSubCategoryId}
                                    componentclassName="select"
                                    id={item.diagonsisSubCategoryId + "-period"}
                                    name="duration"
                                    value={item.duration.period}
                                  >
                                    <option value="D/W/M/Y">D/W/M/Y</option>
                                    {selectDrugDurationTimePeriodOptions.map(
                                      function (option) {
                                        return (
                                          <option value={option.value}>
                                            {option.label}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </td>

                              <td>
                                <span className="actn-btn">
                                  <a
                                    href="#"
                                    onClick={(e) => editDiagnosisPopUp(item)}
                                  >
                                    <img src={EditIcon} alt="edit" />
                                  </a>
                                  <a href="#" style={{ color: "red" }}>
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      onClick={() => {
                                        deleteLongTermConfirm(
                                          item.diagonsisSubCategoryId
                                        );
                                      }}
                                      subcatid={item.diagonsisSubCategoryId}
                                      complaint={item.diagonsisSubCategoryName}
                                    />
                                  </a>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <h5>Intercurrent</h5>
                    <div className="center-content-table">
                      <table className="table table-bordered diagnosis-table-style">
                        <thead>
                          <tr>
                            <th scope="col" style={{ minWidth: "40px" }}>
                              #
                            </th>
                            <th scope="col" style={{ minWidth: "150px" }}>
                              Intercurrent Illness
                            </th>
                            <th scope="col" style={{ minWidth: "90px" }}>
                              Added on
                            </th>
                            <th scope="col" style={{ minWidth: "80px" }}>
                              Duration
                            </th>
                            <th scope="col" style={{ minWidth: "80px" }}>
                              Action
                            </th>
                            <th scope="col" style={{ minWidth: "60px" }}>
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.interCurrent.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                              <th scope="row">{idx + 1}</th>
                              <td>{item.diagonsisSubCategoryName}</td>
                              <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                              <td>
                                <div className="durationSelects">
                                  <select
                                    className="daysSelect"
                                    onChange={(e) => {
                                      handleDuration(e, "interCurrent", "no");
                                    }}
                                    subcatid={item.diagonsisSubCategoryId}
                                    componentclassName="select"
                                    id={item.diagonsisSubCategoryId + "-no"}
                                    name="duration"
                                    value={item.duration.no}
                                  >
                                    <option value="No">No</option>
                                    {selectDrugDurationDayOptions.map(function (
                                      option
                                    ) {
                                      return (
                                        <option value={option.value}>
                                          {option.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <select
                                    className="weekSelect"
                                    onChange={(e) => {
                                      handleDuration(
                                        e,
                                        "interCurrent",
                                        "period"
                                      );
                                    }}
                                    subcatid={item.diagonsisSubCategoryId}
                                    componentclassName="select"
                                    id={item.diagonsisSubCategoryId + "-period"}
                                    name="duration"
                                    value={item.duration.period}
                                  >
                                    <option value="D/W/M/Y">D/W/M/Y</option>
                                    {selectDrugDurationTimePeriodOptions.map(
                                      function (option) {
                                        return (
                                          <option value={option.value}>
                                            {option.label}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </td>

                              <td>
                                <span className="actn-btn">
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      editDiagnosisPopUp(
                                        item,
                                        item.diagonsisSubCategoryId,
                                        e
                                      )
                                    }
                                  >
                                    <img src={EditIcon} alt="edit" />
                                  </a>
                                  <a href="#" style={{ color: "red" }}>
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      onClick={() =>
                                        deleteIntercurrentConfirm(
                                          item.diagonsisSubCategoryId
                                        )
                                      }
                                      subcatid={item.diagonsisSubCategoryId}
                                      complaint={item.diagonsisSubCategoryName}
                                    />
                                  </a>
                                </span>
                              </td>
                              <td>
                                {(item.discontinue === "0" ||
                                  item.discontinue === "1") &&
                                  item.oldAppointmentId !==
                                  item.appointmentId && (
                                    <span>
                                      <a href="#">
                                        <i
                                          onClick={() => {
                                            handleDiscontinueIntercurrent(
                                              item.diagonsisSubCategoryId
                                            );
                                          }}
                                          subcatid={item.diagonsisSubCategoryId}
                                          complaint={
                                            item.diagonsisSubCategoryName
                                          }
                                          aria-hidden="true"
                                        >
                                          {item.discontinue === "0"
                                            ? "Discontinue"
                                            : "Continue"}
                                        </i>
                                      </a>
                                    </span>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="complaints-action">

                      <AddDiagnosis
                        setModalStatusAddDiagnosis={setModalStatusAddDiagnosis}
                        modalStatusAddDiagnosis={modalStatusAddDiagnosis}
                        addDiagnosis={addDiagnosis}
                        setAddDiagnosis={setAddDiagnosis}
                        diagnosisCategoryList={diagonsisCategoryList}
                        dispatch={dispatch}
                        props={addRareDiagnosis}
                        diagnosisData={diagnosisData}
                        diagnosisDuration={diagnosisDuration}

                      />
                    </div> */}
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="sytems-wrapper">
                    <div
                      id="autoCompleteBox"
                      class="form-group autoComplete search-complaint"
                      style={{ zIndex: "999" }}
                      ref={wrapperRef}
                    >
                      <FormGroup>
                        <FormControl
                          type="text"
                          maxlength="100"
                          autocomplete="off"
                          onChange={(e) => {
                            searchDiagnosis(e);
                          }}
                          id="txtSearchDiagnosis"
                          placeholder="Search Diagnosis"
                          className="form-control "
                        />
                      </FormGroup>
                    </div>
                    {isSpecialCharacter && (
                      <div style={{ color: "red", fontSize: "13px" }}>
                        Special Characters are not allowed.
                      </div>
                    )}
                    {searchLength && (
                      <div style={{ color: "red", fontSize: "13px" }}>
                        <p>Maximum 100 Characters are allowed.</p>
                      </div>
                    )}
                    {noDataFound && (
                      <div style={{ color: "red", fontSize: "13px" }}>
                        <p>No Data Found.</p>
                      </div>
                    )}
                    <div className="sytems-wrapper-header">
                      <h5>Diagnosis Selection</h5>
                      <div className="systems-action">
                        <AddDiagnosis
                          setModalStatusAddDiagnosis={
                            setModalStatusAddDiagnosis
                          }
                          modalStatusAddDiagnosis={modalStatusAddDiagnosis}
                          addDiagnosis={addDiagnosis}
                          setAddDiagnosis={setAddDiagnosis}
                          diagnosisCategoryList={diagonsisCategoryList}
                          dispatch={dispatch}
                          props={addRareDiagnosis}
                          diagnosisData={diagnosisData}
                          diagnosisDuration={diagnosisDuration}
                        />
                      </div>
                    </div>
                    <div className="systems-lists-wrapper">
                      <h6>Type</h6>
                      <FormGroup className="systems-lists">
                        <div className="form-check form-check-emr form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="Cattype"
                            onClick={() => {
                              getDiagonsisCategory("longTerm");
                            }}
                            id="longTerm"
                            value="value1"
                          />
                          <label className="form-check-label" for="longTerm">
                            Long term diagnosis
                          </label>
                        </div>
                        <div className="form-check form-check-emr form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="Cattype"
                            onClick={() => {
                              getDiagonsisCategory("interCurrent");
                            }}
                            id="interCurrent"
                            value="value2"
                          />
                          <label
                            className="form-check-label"
                            for="interCurrent"
                          >
                            Intercurrent
                          </label>
                        </div>
                      </FormGroup>
                      <hr />
                      <FormGroup className="systems-lists">
                        <div className="systems-lists-master">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              data="patient"
                              onClick={(e) => {
                                getDiagnosisSubCategory(e, "patient");
                              }}
                              id={patientId}
                              value={patientId}
                            />
                            <label className="form-check-label" for={patientId}>
                              Patient List
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              data="clininc"
                              onClick={(e) => {
                                getDiagnosisSubCategory(e, "clinic");
                              }}
                              id={clinicId}
                              value={clinicId}
                            />
                            <label className="form-check-label" for={clinicId}>
                              My List
                            </label>
                          </div>
                        </div>
                        <div className="systems-lists-looped">
                          {diagonsisCategoryList.map((item) => {
                            return (
                              <>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    data="category"
                                    id={item._id}
                                    value={item._id}
                                    onClick={(e) => {
                                      getDiagnosisSubCategory(e, "category");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    for={item._id}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </FormGroup>
                      <div className="suggestion-box">
                        <div className="suggetion-box-header">
                          <h6>Suggestions</h6>
                          <span className="enlarge">
                            <DiagnosisEnlarge
                              setModalDiagnosisEnlarge={
                                setModalStatusDiagnosisEnlarge
                              }
                              ModalStatusDiagnosisEnlarge={
                                modalStatusDiagnosisEnlarge
                              }
                              dispatch={dispatch}
                              DiagnosisEnlarge={diagnosisEnlarge}
                              LongTerm={state.longTerm}
                              InterCurrent={state.interCurrent}
                              LongTermData={state.longTermData}
                              InterCurrentData={state.intercurrentData}
                              diagnosisPatientId={patientId}
                              diagnosisAppointmentId={appointmentId}
                              oldAppointmentId={oldAppointmentId}
                            />
                          </span>
                        </div>
                        {/* <div className="suggestion-box-action">
                          <span className='enlarge'><DiagnosisEnlarge
                            setModalDiagnosisEnlarge={setModalStatusDiagnosisEnlarge}
                            ModalStatusDiagnosisEnlarge={modalStatusDiagnosisEnlarge}
                            dispatch={dispatch}
                            DiagnosisEnlarge={diagnosisEnlarge}
                            LongTerm={state.longTerm}
                            InterCurrent={state.interCurrent}
                            diagnosisPatientId={patientId}
                            diagnosisAppointmentId={appointmentId}
                            oldAppointmentId={oldAppointmentId}

                          />
                          </span>
                        </div> */}
                        <div className="suggestion-list">
                          {diagnosisSubcategory.map((item) => {
                            {
                              count++;
                            }
                            if (count <= 20) {
                              return (
                                <>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={
                                        flag === "masterDiagonsisCategoryList"
                                          ? item.name
                                          : item.diagonsisSubCategoryName
                                      }
                                      onChange={(e) => {
                                        addOrDeleteSubcategory(e);
                                      }}
                                      name="subcat"
                                      id={
                                        flag === "patientDiagonsisList"
                                          ? item.diagonsisSubCategoryId
                                          : item._id
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      for={
                                        flag === "patientDiagonsisList"
                                          ? item.diagonsisSubCategoryId
                                          : item._id
                                      }
                                      title={
                                        flag === "masterDiagonsisCategoryList"
                                          ? item.name
                                          : item.diagonsisSubCategoryName
                                      }
                                    >
                                      {flag === "masterDiagonsisCategoryList"
                                        ? item.name
                                        : item.diagonsisSubCategoryName}
                                    </label>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default withRouter(Diagnosis);
