import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import { clinicListAction } from 'Admin/actions/clinic';
import { planListAction } from 'Admin/actions/master';
import { addClinicSubscriptionAction, clinicSubscriptionAction } from 'Admin/actions/clinic_subscription';
import loadingImg from 'Admin/assets/img/loading.gif';
import Loading from 'react-loading';
import moment from 'moment';
import ClinicHeader from '../ClinicHeader/ClinicHeader';


let Validator = require('validatorjs');
let formArr = {}
let rules = {
  clinic_id: 'required',
  plan_id: 'required',
};
let mess = {
  required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class AddClinicSubscription extends Component {
  constructor(props) {
    console.log(props, "props")
    super(props);
    const answer_array = this.props.location.pathname.split('/');
    let _id_clinic;
    if (this.props.location.state && this.props.location.state._id) {
      _id_clinic = this.props.location.state._id;
      localStorage.setItem('_id_clinic', this.props.location.state._id);
    }
    this.vForm = this.refs.vForm;
    this.state = {
      formArr: [],
      isLogin: true,
      showProcessing: false,
      // clinic_id: answer_array[3] ? answer_array[3] : '',
      // clinicId: answer_array[3] ? answer_array[3] : '',
      clinic_id: _id_clinic,
      clinicId: _id_clinic,
      // Elements
      formData: {
        // clinic_id: answer_array[3] ? answer_array[3] : '',
        clinic_id: _id_clinic,
        clinic_name: "",
        plan_id: "",
        subscription_start_date: "",
        subscription_end_date: "",

      },
      //Lists
      clinicList: [],
      planList: [],
      plan_id: '',
      clinicSubscriptionList: [],
      isLoading: true,
      subStartDate: '',
      subEndDate: '',
      duration: '',
      direction: 'desc',
      order: "startDate",
      offset: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: "",
      limitForm: {},
      //clinicView: this.props && this.props.location && this.props.location.state && this.props.location.state._id,
      clinicView: _id_clinic,
    }


    this.onStartDate = this.onStartDate.bind(this);
    this.onEndDate = this.onEndDate.bind(this);
    this.onDuration = this.onDuration.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onUserCount = this.onUserCount.bind(this);
    if (this.props.location.state && this.props.location.state._id) {
      localStorage.setItem('_id_clinic', this.props.location.state._id);
    }
  }

  componentDidMount() {
    if (typeof this.props.location.state !== 'undefined') {
      let data = this.state.formData;
      data["clinic_name"] = this.props.location?.state?.row?.clinicName;
      data["clinic_id"] = this.props?.location?.state?.row?.clinicId;
      data["subscription_start_date"] = this.props.location.state.subscription_start_date;
      data["subscription_end_date"] = this.props.location.state.subscription_end_date;
      data["clinic_Id_Number"] = this.props?.location?.state?.row?.clinicIdNumber;
      this.setState({ formData: data });
    }

    let data = this.state.limitForm;
    data['limitStatus'] = true;
    data['status'] = 'active';
    this.props.clinicListAction(data);
    this.props.planListAction(data);

    if (this.state.clinicId) {
      let data1 = {};
      data1['clinic_id'] = this.state.clinicId;
      data1['direction'] = this.state.direction;
      data1['order'] = this.state.order;
      data1['offset'] = this.state.offset;
      data1['limit'] = this.state.limit;
      this.props.clinicSubscriptionAction(data1);
    }

  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.isClinicList !== this.props.isClinicList && this.props.isClinicList === false) {
      this.setState({
        clinicList: nextProps.clinicList.data.data
      });
    }
    if (nextProps.isPlanList !== this.props.isPlanList) {
      this.setState({
        planList: nextProps.planList.data.data
      });


    }

    if (nextProps.isAddClinicSubscriptionError !== this.props.isAddClinicSubscriptionError && this.props.isAddClinicSubscriptionError === false) {
      this.setState({ showProcessing: false });
      if (nextProps.addClinicSubscriptionResponse.errors) {
        nextProps.addClinicSubscriptionResponse.errors.map((key, i) => {
          this.setState({ [(key.param) + "Error"]: key.msg });
        });
      }
    }

    if (nextProps.isAddClinicSubscription !== this.props.isAddClinicSubscription && nextProps.addClinicSubscriptionResponse.status === 'Success' && this.state.formData.clinic_name !== '' && this.props.isAddClinicSubscription === false) {
      this.props.handleClick('success', nextProps.addClinicSubscriptionResponse.msg);
      this.handleClickSubscription();
      this.setState({ showProcessing: false, subStartDate: '', subEndDate: '' });
      // this.props.history.push(`/admin/clinic-subscription`);
      this.setState({ plan_id: '' });

    }

    if (nextProps.isClinicSubscriptionList !== this.props.isClinicSubscriptionList && this.props.isClinicSubscriptionList === false) {
      let data = this.state.formData;
      if (nextProps && nextProps.clinicSubscriptionList && nextProps.clinicSubscriptionList.data && nextProps.clinicSubscriptionList.data.data[0] && nextProps.clinicSubscriptionList.data.data[0].startDate && nextProps.clinicSubscriptionList.data.data[0].endDate) {
        data["subscription_start_date"] = nextProps.clinicSubscriptionList.data.data[0].startDate;
        data["subscription_end_date"] = nextProps.clinicSubscriptionList.data.data[0].endDate;
      } else {
        data["subscription_start_date"] = '';
        data["subscription_end_date"] = '';
      }
      this.setState({
        clinicSubscriptionList: nextProps.clinicSubscriptionList.data.data,
        totalCount: nextProps.clinicSubscriptionList.data.count,
        formData: data
      });


    }

    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }
  }

  handleClickSubscription(id = '') {
    if (this.state.clinicId || id || (this.state.formData && this.state.formData.clinic_id)) {

      let data1 = {};
      data1['clinic_id'] = (this.state.formData && this.state.formData.clinic_id) ? this.state.formData.clinic_id : (this.state.clinicId ? this.state.clinicId : id);
      data1['direction'] = this.state.direction;
      data1['order'] = this.state.order;
      data1['offset'] = this.state.offset;
      data1['limit'] = this.state.limit;
      this.props.clinicSubscriptionAction(data1);
    }
  }

  addClinicSubscription(evt) {
    console.log(this.state, "FMsdCGqxyQQ2M6j68L7N")
    evt.preventDefault();
    const _this = this;
    if (this.allValidate(false)) {
      _this.setState({ showProcessing: true });
      _this.props.addClinicSubscriptionAction(this.state);
    }
    //validation.errors()
  }

  allValidate(check) {
    if (!check) {
      formArr = []
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });

  };

  handleClinicChange(event) {
    let data = this.state.formData;
    data["clinic_name"] = event.target[event.target.selectedIndex].text;
    data["clinic_id"] = event.target.value;
    this.setState({ formData: data });

    let clinicId = event.target.value;
    if (clinicId)
      this.handleClickSubscription(clinicId);
  }

  handlePlanChange(event) {
    let data = this.state.formData;
    data["plan_id"] = event.target.value;

    this.setState({ plan_id: event.target.value });

    this.setState({ formData: data });

    let plan = this.state.planList;
    var new_date = '';
    var new_endDate = '';
    var duration = '';

    if (plan && plan.length > 0) {
      plan.map((v, i) => {
        if (v._id === event.target.value) {

          duration = v.duration;


          if (data.subscription_end_date) {
            if (new Date() > new Date(data.subscription_end_date)) {
              new_date = moment();
              new_endDate = moment(new_date).add(Number(v.duration - 1), 'days');
            } else if (new Date() < new Date(data.subscription_end_date)) {
              new_date = moment(data.subscription_end_date).add(Number(1), 'days');
              new_endDate = moment(new_date).add(Number(v.duration - 1), 'days');
            }
          }
          else {

            new_date = moment();
            new_endDate = moment(new_date).add(Number(v.duration - 1), 'days');

          }

        }
      })
    }

    this.setState({ subStartDate: new_date ? moment(new_date).format("MMM DD, YYYY") : '', subEndDate: new_endDate ? moment(new_endDate).format("MMM DD, YYYY") : '', duration });

  }

  onStartDate(cell, row, enumObject, rowIndex) {
    return (row && row.startDate) ? moment(row.startDate).format("MMM DD, YYYY") : "";
  }

  onEndDate(cell, row, enumObject, rowIndex) {
    return (row && row.endDate) ? moment(row.endDate).format("MMM DD, YYYY") : "";
  }

  onDuration(cell, row, enumObject, rowIndex) {
    if (row && row.startDate && row.endDate) {
      let dateDiff = new Date(row.endDate).valueOf() - new Date(row.startDate).valueOf();
      dateDiff = dateDiff / 86400000;

      return Math.round(dateDiff);
    }

  }

  onUserCount(cell, row, enumObject, rowIndex) {

    return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return "No data found!";
    }
  }

  goBackFun(e) {

    this.props.history.replace(sessionStorage.getItem("prvUrl"))
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    data['clinic_id'] = this.state.clinicId;
    if (this.state.searchText === "") {
      data['direction'] = this.state.direction;
      data['order'] = this.state.order;
      data['offset'] = (page === -1) ? this.state.offset : page - 1;
      data['limit'] = sizePerPage;
    } else {
      data['direction'] = this.state.direction;
      data['order'] = this.state.order;
      data['offset'] = (page === -1) ? this.state.offset : page - 1;
      data['limit'] = sizePerPage;
    }

    this.setState({ sizePerPage: sizePerPage })
    this.setState({ currentPage: page })
    this.props.clinicSubscriptionAction(data)
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {}
        data['clinic_id'] = this.state.clinicId;
        data['direction'] = this.state.direction;
        data['order'] = this.state.order;
        data['offset'] = this.state.offset;
        data['limit'] = this.state.limit;
        data['search'] = text;
        this.setState({ sizePerPage: 10 })
        this.props.clinicSubscriptionAction(data);
      }
    } else {
      this.componentDidMount();
    }
    this.setState({ searchText: text });
  }

  render() {

    const { formData, subStartDate, subEndDate, duration } = this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      onSearchChange: this.onSearchChange,
      noDataText: this._setTableOption(),
      // hideSizePerPage: false


    };

    return (
      <div className="main-content" style={{ padding: '15px 0px' }}>
        <Grid fluid>
          <Row>
            <Col md={12}>
              {this.state.clinicView &&
                <ClinicHeader componentData={{ clinicId: this.state.clinicView }} />
              }
              <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                <img src={loadingImg} alt="Loading" width="40px" />
                <center>Logging In - Please Wait</center>
              </div>

              <Card


                title={<legend className="line-removes spk">
                  {formData && formData.clinic_id && <span className="headingBox" >Subscription...!</span>}
                  {!this.state.clinicView &&
                    <Button className="go-back-btn" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                  }
                </legend>}


                content={
                  <div>
                    <Row className='form-top-spc'>
                      <Form horizontal>
                        <Col md={12} className='p-0'>
                          <Col md={4}>
                            <div className="header notations-title notify-title-sec font-style-clinic" style={{ padding: "0px" }}>
                              Clinic Id :
                              <b>{formData?.clinic_Id_Number || "N/A"}</b>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="header notations-title notify-title-sec font-style-clinic" style={{ padding: "0px" }}>
                              Clinic Name :
                              <b>{formData?.clinic_name || "N/A"}</b>
                            </div>
                          </Col>

                          <Col md={2}>
                          </Col>
                          {/* <div className="fuls-how-m">
                            <FormGroup style={{ margin: 0 }}>
                              <Col componentClass={ControlLabel} sm={1} >
                                {!this.state.clinicId &&
                                  <div>
                                    Clinic
                                  </div>
                                }
                              </Col>
                              <Col sm={7}>

                                {(this.state.clinicId) ?
                                  ""
                                  :
                                  <FormControl componentClass="select" name="clinic_id" onChange={e => { this.handleChange(e); this.handleClinicChange(e); }} value={this.state.formData.clinic_id}>
                                    <option value="">Please select</option>
                                    {this.state.clinicList.map(function (item) {

                                      return <option key={item._id} value={item._id}>{item.name}</option>
                                    })}
                                  </FormControl>
                                }
                                <span className="errorMsg">{this.state.clinic_idError}{this.state.formArr.clinic_id && validation.errors.first('clinic_id')}</span>
                              </Col>
                            </FormGroup></div> */}
                          {/* <div className="fuls-how-m">
                            <Col componentClass={ControlLabel} sm={1} md={1} className="l-0">
                              Plan <span className="star">*</span>
                            </Col>
                            <Col sm={7}>
                              <FormControl componentClass="select" name="plan_id" onChange={e => { this.handleChange(e); this.handlePlanChange(e); }} value={this.state.plan_id}>
                                <option value="">Please select</option>
                                {this.state.planList.map(function (item) {
                                  return <option key={item._id} value={item._id}>{item.planName}</option>
                                })}</FormControl>
                              <span className="errorMsg">{this.state.plan_idError}{this.state.formArr.plan_id && validation.errors.first('plan_id')}</span>
                            </Col>
                            <div className="sub-btn-box htght col-sm-4">
                              <Row>
                                <Col md={12}>
                                  <Button fill bsStyle="info" type="button" onClick={this.addClinicSubscription.bind(this)}>Submit</Button>
                                </Col>
                              </Row>
                            </div>
                          </div> */}
                          {/* {(subStartDate && subEndDate) &&
                            <FormGroup>
                              <Col sm={12}>
                                <span>Duration: {duration} days
                                  <span className="spc-rity"><i class="fa fa-hourglass-start" aria-hidden="true"></i>
                                    <b>Start: </b>{subStartDate}</span>
                                  <span className="spc-rity"><i class="fa fa-hourglass-end" aria-hidden="true"></i><b>End: </b> {subEndDate}</span>
                                </span>
                              </Col>
                            </FormGroup>
                          } */}

                        </Col>

                        <Row className='sub-row p-0'>
                          <Col md={6} componentClass={ControlLabel} className="label-sec" style={{ marginTop: "35px" }}>
                            Plan <span className="star">*</span>
                            <FormControl componentClass="select" name="plan_id" onChange={e => { this.handleChange(e); this.handlePlanChange(e); }} value={this.state.plan_id}>
                              <option value="">Please select</option>
                              {this.state.planList.map(function (item) {
                                return <option key={item._id} value={item._id}>{item.planName}</option>
                              })}</FormControl>
                            <span className="errorMsg">{this.state.plan_idError}{this.state.formArr.plan_id && validation.errors.first('plan_id')}</span>
                          </Col>
                          <Col md={6} style={{ marginTop: "54px" }}>
                            <Button fill bsStyle="info" type="button" onClick={this.addClinicSubscription.bind(this)}>Submit</Button>
                          </Col>
                        </Row>
                        {(subStartDate && subEndDate) &&
                          <FormGroup style={{ marginTop: "20px" }}>
                            <Col sm={12}>
                              <span>Duration: {duration} days
                                <span className="spc-rity"><i class="fa fa-hourglass-start" aria-hidden="true"></i>
                                  <b>Start: </b>{subStartDate}</span>
                                <span className="spc-rity"><i class="fa fa-hourglass-end" aria-hidden="true"></i><b>End: </b> {subEndDate}</span>
                              </span>
                            </Col>
                          </FormGroup>
                        }
                      </Form>


                      {/* {formData && formData.clinic_id ?
                        <Col md={12}>
                          <div className="resp-t table-resposive clinic-subn">
                            <BootstrapTable
                              selectRow="{selectRowProp}"
                              deleteRow={false}
                              data={this.state.clinicSubscriptionList}
                              search={false}
                              multiColumnSearch={false}
                              pagination={true}
                              options={options}
                              striped
                              hover
                              condensed
                              scrollTop={'Bottom'}
                              remote={true}
                              fetchInfo={{ dataTotalSize: this.state.totalCount }}


                            >
                              <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='_id' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Clinic Name' }} dataField='clinicName'>Clinic Name</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Name' }} dataField='planName'>Name</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Amount' }} dataField='amount' >Amount</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Duration' }} dataField='' dataFormat={this.onDuration} >Duration</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Start Date' }} dataField='startDate' dataFormat={this.onStartDate} >Start Date</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'End Date' }} dataField='endDate' dataFormat={this.onEndDate} >End Date</TableHeaderColumn>
                            </BootstrapTable>
                          </div>
                        </Col>
                        : <Row><Col md={12}></Col></Row>

                      } */}
                    </Row>
                  </div>
                }
              />
              {/*
                                <div>
                                ftTextLeft
                                legend={
                                    <Button fill bsStyle="info" type="button" onClick={this.addClinicSubscription.bind(this)}>Submit</Button>
                                }
                                </div>
                                */}

            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {

    planList: state.master.planList,
    isPlanList: state.master.isPlanList,
    isPlanListError: state.master.isPlanListError,

    clinicList: state.clinic.ClinicList,
    isClinicList: state.clinic.isClinicList,
    isClinicListError: state.clinic.isClinicListError,

    isAddClinicSubscription: state.clinicSubscription.isAddClinicSubscription,
    isAddClinicSubscriptionError: state.clinicSubscription.isAddClinicSubscriptionError,
    addClinicSubscriptionResponse: state.clinicSubscription.addClinicSubscriptionResponse,

    clinicSubscriptionList: state.clinicSubscription.clinicSubscriptionList,
    isClinicSubscriptionList: state.clinicSubscription.isClinicSubscriptionList,
    isClinicSubscriptionListError: state.clinicSubscription.isClinicSubscriptionListError,

  }
}
// export default AddClinic;
export default withRouter(connect(mapStateToProps, { clinicListAction, planListAction, addClinicSubscriptionAction, clinicSubscriptionAction })(AddClinicSubscription));
